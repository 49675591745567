import type { SegmentAnalytics } from '@segment/analytics.js-core';

import {
	FC,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import { useRecoilState } from 'recoil';

import { Loader } from '@storybook/loader/loader';
import { UnauthorizedDomain } from 'components';
import { InitialMain } from 'initial-main';
import { AllRoutes } from './routes';
import {
	useCookie,
	useLocation,
	useGetLocationDetails,
} from 'hooks';

import { useCheckOffline } from 'views/offline/hooks/use-check-offline';
import { Offline } from 'views/offline/offline';
import { useUserVerification } from 'views/user-verification/stores/hooks';

/*
@avinashSatschel: added Highchart as we have removed highchart CDN from index.html as well as from web-component.*js
*/
import * as Highcharts from 'highcharts';
import HighChartsMore from 'highcharts/highcharts-more';
import HighChartAccesssbility from 'highcharts/modules/accessibility';
import HighChartDataModule from 'highcharts/modules/data';
import HightChartOrgnization from 'highcharts/modules/organization';
import HighChartSanky from 'highcharts/modules/sankey';
import HighChartTreeMap from 'highcharts/modules/treemap';
import HighChartVariablePie from 'highcharts/modules/variable-pie';
HighChartsMore(Highcharts);
HighChartSanky(Highcharts);
HighChartDataModule(Highcharts);
HighChartTreeMap(Highcharts);
HighChartVariablePie(Highcharts);
HightChartOrgnization(Highcharts);
HighChartAccesssbility(Highcharts);
/*
@avinashSatschel: setting Highcharts in window (global object) so that we do not need to change in diffect files as window.HighChart was being used in different Components
*/
window.Highcharts = Highcharts;

//@avinash need to set undefined as there is issue with fesh mesh
(window as any).process = undefined;

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-tooltip/dist/react-tooltip.css';
import { useRecoilValue } from 'recoil';
import { SessionOutModal, ShowWarningPopup } from 'views/session-out';
import { GoogleLocation, WebTokenState, loginState } from 'global-stores';
import './main.scss';
import { loadStripe } from '@stripe/stripe-js';
import { REACT_APP_STRIPE_SECRET } from 'envs';

declare global {
	interface Window {
		analytics: SegmentAnalytics.AnalyticsJS & { load: (key: string) => void };
	}
}

const params = new URLSearchParams(window.location.search);
const code = params.get('code');
const phone = params.get('phone');
const countryCode = params.get('countryCode');
const redirectState = params.get('redirectSocialState');
const type = params.get('type');

export const stripePromise = loadStripe(REACT_APP_STRIPE_SECRET);

//admin.stage.satschel.com/login?phone=6393177038&countryCode=+91&redirectSocialState=https://9qhzlh.csb.app/login?phone=6393177038_91&countryCode=+91

let apiCalled = false;
export const App: FC<any> = ({ webComponentProps }) => {
	const { isOnline } = useCheckOffline();
	const { verifyEmailWithCode } = useUserVerification();
	const webToken = useRecoilValue(WebTokenState);
	const { get: getCookie } = useCookie();
	const localUserData = getCookie('user');
	const { accessToken: token,loggedIn=false } = useRecoilValue(loginState);
	const googleLocation = useRecoilValue(GoogleLocation);

	const accessToken =
		webToken?.length > 0 ? webToken : token || localUserData?.accessToken;

	const [isWhiteListedDomainVisible, setIsWhiteListedDomainVisible] =
		useState<boolean>(true);

	const { get: getAccessToken } = useCookie();
	const { locationInfo } = useLocation();
	const { getGeoInfo } = useGetLocationDetails();

	const [loading, setLoading] = useState<boolean>(
		webComponentProps.admin !== 'true' && getAccessToken('user')?.accessToken
	);
	const [isOpen, setIsOpen] = useRecoilState(ShowWarningPopup);

	const closeModal = useCallback(() => {
		setIsOpen(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		if (phone === 'null' && countryCode === 'null') {
			window.location.href = '/login';
		} else if (redirectState && phone && countryCode) {
			const host = redirectState.includes('/login')
				? redirectState
				: `${redirectState}/login`;
			window.location.href = `${host}?phone=${phone}&countryCode=${countryCode}`;
			return;
		}
	}, [accessToken]);

	useEffect(() => {
		if (code && !apiCalled && !type) {
			verifyEmailWithCode(code);
			apiCalled = true;
		}
		// eslint-disable-next-line
	}, [code]);

	useEffect(() => {
		if (locationInfo && !googleLocation&&!loggedIn) {
			const { longitude, latitude } = locationInfo;
			getGeoInfo({
				lat: latitude as number,
				lng: longitude as number,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locationInfo]);

	const renderRoutepage = useMemo(
		() =>
			!isWhiteListedDomainVisible ? <UnauthorizedDomain /> : <AllRoutes />,
		[isWhiteListedDomainVisible]
	);

	const renderPage = useMemo(() => {
		return loading ? (
			<div className="loader-center">
				<Loader />
			</div>
		) : (
			renderRoutepage
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, renderRoutepage]);

	return (
		<>
			<div className="simplici--webview-full-width">
				<InitialMain
					webComponentProps={webComponentProps}
					isWhiteListedDomainVisible={setIsWhiteListedDomainVisible}
					isLoading={setLoading}
				/>
				{!isOnline ? <Offline /> : renderPage}
				<ToastContainer
					position="top-center"
					autoClose={2000}
					hideProgressBar
					closeOnClick
					pauseOnHover
					transition={Slide}
					theme="dark"
					limit={1}
				/>
				{isOpen && <SessionOutModal isOpen={isOpen} closeModal={closeModal} />}
			</div>
		</>
	);
};
