import { Elements } from '@stripe/react-stripe-js';

import { AddCardForm } from './add-card-form';
import { FC } from 'react';
import { stripePromise } from 'main';

interface IAddCard {
	from?: 'SIGNUP' | 'HOME';
}
export const AddCard: FC<IAddCard> = ({ from }) => {
	return (
		<>
			<div className="App payment-app">
				<Elements stripe={stripePromise}>
					<AddCardForm from={from} />
				</Elements>
			</div>
		</>
	);
};
