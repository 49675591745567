import { useRecoilValue } from 'recoil';
import { UserRoleState, loginState } from 'global-stores';
import { envHost } from './env-url';
import { REACT_APP_LIQUIDITY_BUSINESSID } from 'envs';
// import { envHost } from './env-url';

type EVENTS =
	| 'create-new-onboarding'
	| 'flow-select-steps'
	| 'flow-builder'
	| 'flow-delivery-method'
	| 'flow-publish'
	| 'create-new-fund'
	| 'create-fund-flow-name'
	| 'create-fund-flow-csv'
	| 'create-new-signedDoc-template'
	| 'config-signedDoc-template'
	| 'create-new-credential'
	| 'invite-new-user'
	| 'sent-user-invite'
	| 'sent-user-reinvite'
	| 'signup'
	| 'billing-info'
	| 'billing-info-invoice'
    | 'invite_sent_web'

export const useTrackEvents = () => {
	const user = useRecoilValue(loginState);
	const userRole = useRecoilValue(UserRoleState);

	// const isProduction = envHost === 'prod';

	const trackEvents = (eventName: EVENTS, properties: unknown) => {
		const isProd = /app.simplici.io/gi.test(window.location.host);
		const isFromLiquidity = REACT_APP_LIQUIDITY_BUSINESSID === user?.business && isProd
		
		const {
			role,
			developer,
			plan,
			business,
			status,
			linkedInUrl,
			name,
			countryCode,
			phone,
			sandboxStatus,
			approveStatus,
			email,
			userId,
			accountType,
			websiteUrl,
			companyName,
			isAgentPermitted,
			isVerifiedPhone,
			isVerifiedEmail,
			loggedIn,
			createdAt,
			firstName,
			lastName,
			image,
			billingType,
		} = user ?? {};

		const userData = {
			role,
			developer,
			plan,
			business,
			status,
			linkedInUrl: linkedInUrl ?? '',
			name,
			countryCode,
			phone,
			sandboxStatus,
			businessStatus: approveStatus,
			email,
			userId,
			accountType: accountType ?? '',
			websiteUrl,
			companyName: companyName ?? '',
			isAgentPermitted,
			isVerifiedPhone,
			isVerifiedEmail,
			loggedIn,
			createdAt,
		};

		try {
			// if (isProduction) {
		if(isFromLiquidity){
			(window as any).gtag('event', `${envHost.toUpperCase()}_Liquidity_User_${eventName}`, {
				customer_no: userId ?? '',
				user_first_name: firstName ?? '',
				user_last_name: lastName ?? '',
				user_email: email ?? '',
				user_firm: companyName ?? '',
				phone: phone ?? '',
				last_request: new Date().toLocaleString(),
				created_at: createdAt ?? '',
				avatar: image ?? '',
				company_id: business ?? '',
				plan_type: accountType,
				plan_status: billingType ?? '',
				user_type: role ?? '',
				firm_url: websiteUrl ?? '',
				firm_seats: userRole?.length ?? 0,
			});
		}
			(window as any).Intercom('trackEvent', eventName, {
				customer_no: userId ?? '',
				user_first_name: firstName ?? '',
				user_last_name: lastName ?? '',
				user_email: email ?? '',
				user_firm: companyName ?? '',
				phone: phone ?? '',
				last_request: new Date().toLocaleString(),
				created_at: createdAt ?? '',
				avatar: image ?? '',
				company_id: business ?? '',
				plan_type: accountType,
				plan_status: billingType ?? '',
				user_type: role ?? '',
				firm_url: websiteUrl ?? '',
				firm_seats: userRole?.length ?? 0,
			});
			(window as any).analytics?.track(eventName, { user: userData, data: properties });

			// }
		} catch (error) {
			return;
		}
	};

	return { trackEvents };
};
