import { Elements } from '@stripe/react-stripe-js';
import { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import {
	IPayNowPages,
	PayNowCurrentPage,
	PayNowSteps,
	useGetInviteOnboardingPayment,
} from '../store';
import { PayNow } from './pay-now';
import { PaymentSuccess } from './payment-success';
import { PayNowProcessing } from './processing';
import { stripePromise } from 'main';

const { PROCESSING_PAYMENT } = PayNowSteps;

type IRenderScreen = {
	[key in IPayNowPages]: JSX.Element;
};

export const InviteOnboardingPaymentBody = () => {
	// global states
	const [currentPage, setCurrentPage] = useRecoilState(PayNowCurrentPage);
	// hooks
	const { manageGoBackToPrevStage } = useGetInviteOnboardingPayment();

	// this will bring back to the previous step config modal | sign agreements
	const handleGoBack = useCallback(() => {
		// this hook manages the going back to the prevScreen in the invite modal before payment
		manageGoBackToPrevStage();
	}, [manageGoBackToPrevStage]);

	// this will manage the rendering of the pay now and monthly payment screen
	const payNowScreen = useMemo((): JSX.Element => {
		// this will check if the value of payment type and render the screens accordingly
		return (
			<Elements stripe={stripePromise}>
				<PayNow
					primaryBtnCallback={() => setCurrentPage(PROCESSING_PAYMENT)}
					secondaryBtnCallback={handleGoBack}
					primaryBtnText={'Pay & Invite'}
				/>
			</Elements>
		);
	}, [handleGoBack, setCurrentPage]);

	const renderPaymentScreens: IRenderScreen = useMemo(
		() => ({
			pay_now: payNowScreen,
			processing_payment: <PayNowProcessing />,
			success: <PaymentSuccess />,
		}),
		[payNowScreen]
	);

	// // this will manage the visibility of the (payNow | monthly-billing) toggle in the payment screen

	return <>{renderPaymentScreens[currentPage]}</>;
};
