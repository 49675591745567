import {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { Loader } from '@storybook';
import { API_URL, ROLE } from 'constant';
import {
	AllowedInvitesState,
	BannerStatusState,
	BusinessPaymentCardState,
	BusinessRegistrationState,
	IBusinessRegistrationState,
	IsBannerStatusState,
	IsUserPermissionLoading,
	UserRolesPermissions,
	WebTokenState,
	loginState,
	subscriptionServiceState,
	userDataState,
	userState,
} from 'global-stores';
import {
	INIT_CONFIGURATION,
	initDB,
	useCookie,
	useCustomLoader,
	useFreePlan,
	useNetwork,
	useNotification,
} from 'hooks';
import { SignIn, SignUp } from 'views/authentication';
import { ComplexInitPipelines } from 'views/complex-onboarding-flow';
import { UploadDocuments } from 'views/upload-documents';
import { RoutesChildren, useUserRoles } from '../views/routes-children';
import { ROUTES } from './constants';
import { BusinessProcessing } from 'views/business-processing';
import { BusinessRejected } from 'views/business-rejected';
import { ApprovalStatus } from 'views/user-identity-flow';
import { CompletedPage } from 'views/completed';
import { LinkExpired } from 'views/link-expired';
import { SignUpSessionStatusState } from 'views/new-user-popup';
import { useCompliance } from './hooks';
import { AcountUserTypes } from 'views/settings';
import { SuccessScreen } from 'views/success-screen';
import { envHost } from 'helpers';

initDB(INIT_CONFIGURATION);

const {
	LOGIN,
	SIGNUP,
	NO_ROUTE,
	UPLODAD_DOCUMENTS,
	BUSINESS_PROCESSING,
	COMPLETED,
	BUSINESS_REJECTED,
} = ROUTES;
const { OWNER } = ROLE;

export const AllRoutes = () => {
	const [login, setLogin] = useRecoilState(loginState);
	const setUserD = useSetRecoilState(userDataState);
	const { get: getAccessToken } = useCookie();
	const setUserId = useSetRecoilState(userState);
	const setUserIdData = useSetRecoilState(userState);
	const setBannerStatus = useSetRecoilState(BannerStatusState);
	const setOnlyBannerStatus = useSetRecoilState(IsBannerStatusState);
	const [isEmailVerificationSuccess, setIsEmailVerificationSuccess] =
		useState(false);
	const [isEmailVerificationFailed, setIsEmailVerificationFailed] =
		useState(false);
	const webToken = useRecoilValue(WebTokenState);
	const { set: setCookie, get: getCookie } = useCookie();
	const localUserData = getCookie('user');
	const { post: getComplexPipelineInits } = useNetwork();
	const setPermissionLoading = useSetRecoilState(IsUserPermissionLoading);
	const [isUserLogin, setUserLogin] = useState(false);
	const [allowRoutes, setAllowRoutes] = useState(false);
	const [loading, setLoading] = useState(false);
	const [complexInitPipelines, setComplexInitPipelines] =
		useRecoilState(ComplexInitPipelines);
	const setBusinessRegistration = useSetRecoilState(BusinessRegistrationState);
	const setSubscription = useSetRecoilState(subscriptionServiceState);
	const setBusinessCard = useSetRecoilState(BusinessPaymentCardState);
	const setAllowedInvites = useSetRecoilState(AllowedInvitesState);
	const setUserPermissions = useSetRecoilState(UserRolesPermissions);
	const signUpSessionStatus = useRecoilValue(SignUpSessionStatusState);
	const navigate = useNavigate();
	const { fetchLoader } = useCustomLoader();

	const { loggedIn, accessToken: token } = useMemo(() => login ?? {}, [login]);
	const accessToken = useMemo(
		() =>
			webToken?.length > 0 ? webToken : token || localUserData?.accessToken,
		[localUserData?.accessToken, token, webToken]
	);
	const {
		haveKybKyc = false,
		haveKyc = false,
		accountType = '',
	} = useFreePlan();
	const { customUserTab } = useUserRoles();
	const { getComplianceData, fetchSessionDeatil } = useCompliance();
	const {
		get: userDetails,
		data: userData,
		error: userError,
		status: userStatus,
		post: userLogin,
	} = useNetwork();
	const { errorNotification } = useNotification();

	useLayoutEffect(() => {
		const redirectState = localStorage.getItem('redirectOrigin');
		if (accessToken && redirectState) {
			window.location.href = redirectState;
			localStorage.removeItem('redirectOrigin');
			return;
		}
	}, [accessToken]);

	const initUserData = useCallback(async () => {
		if (userData?.data?.length && userStatus) {
			setUserLogin(true);
			const {
				firstName,
				lastName,
				business,
				email,
				id,
				role,
				developer,
				isVerifiedEmail,
				isVerifiedPhone,
				invitedDate,
				isAgentPermitted,
				countryCode,
				phone,
				image,
				passKeyCount,
				sandboxStatus,
				userHash,
				createdAt,
				billingType,
				businessPrices,
				mfaEnabled,
				role: permissionRole,
			} = userData?.data?.[0] ?? {};

			const { onboardingService, simpliciSignService } = businessPrices ?? {};
			const { freeCredits: onboarding } = onboardingService ?? {};
			const { freeCredits: simpliciSign } = simpliciSignService ?? {};

			setAllowedInvites({
				onboarding: onboarding ?? 0,
				simpliciSign: simpliciSign ?? 0,
			});

			const userNameData = {
				...userData?.data[0],
				name: firstName + ' ' + lastName,
			};
			const {
				plan = '',
				status = '',
				_id: businessId,
				approveStatus,
				linkedInUrl,
				name: companyName,
				websiteUrl,
				subscription,
				accountType = '',
				kyb,
				kyc,
				billingAddress,
				ownBilling,
				differentCompany,
				parentBusiness,
				parentBusinessId = '',
				primaryUser,
				createdAt: businessCreatedAt,
			} = business;
			setSubscription(subscription ?? {});
			if (billingAddress) {
				setBusinessCard((prev: any) => ({
					...prev,
					billingAddress,
				}));
			}
			setLogin({
				...login,
				accessToken,
				business: businessId,
				email,
				name: firstName + ' ' + lastName,
				userId: id,
				plan,
				status,
				isVerifiedEmail,
				isVerifiedPhone,
				invitedDate,
				firstName,
				lastName,
				isAgentPermitted,
				countryCode,
				phone,
				image,
				developer,
				approveStatus,
				passKeyCount,
				linkedInUrl,
				companyName,
				websiteUrl,
				accountType,
				userHash,
				createdAt,
				kyb,
				kyc,
				role: role?.name ?? OWNER,
				roleId: role?._id ?? '',
				sandboxStatus: false,
				billingType,
				businessId: role?.businessId ?? '',
				ownBilling,
				differentCompany,
				mfaEnabled,
				parentBusiness,
				parentBusinessId,
				primaryUser,
				businessCreatedAt,
			});
			// Shahbaaz: Adding event Tabs for POC
			if (envHost === 'prod') {
				 permissionRole.permissions['events'] = {
				 	read: false,
				 	write: false,
				 };
			}
			setUserPermissions(permissionRole);
			const cookieData = {
				accessToken: accessToken ?? '',
			};
			setCookie('userEmail', { email });
			setCookie('user', { ...cookieData });
			setUserIdData({ userId: id });
			setUserD(userNameData);
			setUserId({
				userId: id,
			});

			setBannerStatus(true);
			// @TODO: AWADHESH
			// setBannerStatus(isVerifiedEmail === true || invitedDate != null);
			setOnlyBannerStatus(
				sandboxStatus ?? true
					? true
					: isVerifiedEmail === true || invitedDate != null
			);
			// Token set
			if (accessToken) await getComplianceData(accessToken);

			// Setting business Data of user
			setBusinessRegistration((prev: IBusinessRegistrationState) => ({
				...prev,
				approveStatus,
				accountType,
				kyc: prev?.kyc ? prev?.kyc : kyc,
				kyb: prev?.kyb ? prev?.kyb : kyb,
			}));
			setPermissionLoading(false);
			setUserLogin(false);
			setAllowRoutes(true);
		}
		if (userError) {
			errorNotification('Failed to user data.');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData]);

	useEffect(() => {
		initUserData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData]);

	//Intercom initilize for chat support
	useEffect(() => {
		if (window.location.host === 'app.simplici.io') {
			try {
				if (login.email) {
					(window as any).Intercom('boot', {
						app_id: 'tphbl5gj',
						name: login.name,
						email: login.email,
						user_id: login.userId,
						user_hash: login.userHash,
					});

					(window as any).FS?.identify?.(login.userId);
					(window as any).FS?.setUserVars?.({
						displayName: login.name,
						email: login.email,
					});
				} else
					(window as any).Intercom('boot', {
						app_id: 'tphbl5gj',
						user_hash: login.userHash,
					});
				// eslint-disable-next-line no-empty
			} catch (error) {}
		}
	}, [login]);

	useEffect(() => {
		if (login?.isVerifiedEmail) return;
		const params = new URLSearchParams(window.location.search);
		const verificationType = params.get('type');
		const emailVerificationId = params.get('code');

		if (verificationType === 'emailVerification') {
			const payload = { code: emailVerificationId, type: 'verifyEmail' };
			const emailVerify = async () => {
				setLoading(true);
				await userLogin(API_URL.USER_LOGIN, payload).then((res: any) => {
					if (res?.success) {
						setIsEmailVerificationSuccess(true);
					} else setIsEmailVerificationFailed(true);
				});
				setLoading(false);
			};
			emailVerify();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getComplexPipelineInit = useCallback(async () => {
		const resp = await getComplexPipelineInits(API_URL.COMPLEX_INIT, {});
		if (resp) {
			const updatedResp = JSON.parse(JSON.stringify(resp));
			//avinashSatschel remove ssn from stepVariable as we have merged ssn with addressVerification so we dont need to keep ssn object
			updatedResp.stepVariables = updatedResp.stepVariables.filter(
				(el: any) => el.key !== 'ssn'
			);
			setComplexInitPipelines(updatedResp);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const cookies = getAccessToken('user');
		//@avinash : added accesstoken variable with or condition for webcomponent
		if (loggedIn && (cookies?.accessToken || accessToken)) {
			fetchLoader(cookies?.accessToken || accessToken);
			userDetails(`${API_URL.CLIENT_SIGNUP}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loggedIn]);

	// Getting complex nodes
	useEffect(() => {
		if (!accessToken) return;
		if (Object.keys(complexInitPipelines).length === 0) {
			getComplexPipelineInit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessToken, complexInitPipelines]);

	// Awadhesh: stop the splash loader for dashboard screen
	useEffect(() => {
		const element = document.querySelector('#splash-screen-loader');
		element?.remove();
	}, []);

	const handleClose = useCallback(() => {
		setIsEmailVerificationFailed(false);
		navigate(ROUTES.LOGIN);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!signUpSessionStatus?.sessionIds.length) return;

		const { sessionIds } = signUpSessionStatus;
		if (accountType === AcountUserTypes.Person && !haveKyc) {
			sessionIds.forEach(session => {
				fetchSessionDeatil(session.sessionId);
			});
			return;
		}

		if (accountType === AcountUserTypes.Entity && !haveKybKyc) {
			sessionIds.forEach(session => {
				fetchSessionDeatil(session.sessionId);
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signUpSessionStatus, haveKyc, accountType, haveKybKyc]);

	const renderMainRoutes = useMemo(() => {
		if (accessToken || loggedIn) {
			if (loggedIn) {
				if (
					signUpSessionStatus?.approveStatus?.toLowerCase() ===
						ApprovalStatus.Pending?.toLowerCase() ||
					login['approveStatus']?.toLowerCase() ===
						ApprovalStatus.Pending?.toLowerCase()
				) {
					return (
						<Routes>
							{allowRoutes && (
								<Route path={COMPLETED} element={<CompletedPage />} />
							)}
							<Route path="*" element={<Navigate to={COMPLETED} />} />
						</Routes>
					);
				}

				if (
					signUpSessionStatus?.approveStatus?.toLowerCase() ===
						ApprovalStatus.UnderReview?.toLowerCase() ||
					login['approveStatus']?.toLowerCase() ===
						ApprovalStatus.UnderReview?.toLowerCase()
				) {
					return (
						<Routes>
							<Route path={UPLODAD_DOCUMENTS} element={<UploadDocuments />} />
							<Route
								path={BUSINESS_PROCESSING}
								element={<BusinessProcessing />}
							/>
							<Route path="*" element={<Navigate to={BUSINESS_PROCESSING} />} />
						</Routes>
					);
				}

				if (
					signUpSessionStatus?.approveStatus?.toLowerCase() ===
						ApprovalStatus.Rejected?.toLowerCase() ||
					login['approveStatus'] === ApprovalStatus.Rejected?.toLowerCase()
				) {
					return (
						<Routes>
							<Route path={UPLODAD_DOCUMENTS} element={<UploadDocuments />} />
							<Route path={BUSINESS_REJECTED} element={<BusinessRejected />} />
							<Route path="*" element={<Navigate to={BUSINESS_REJECTED} />} />
						</Routes>
					);
				}
			}
			return <RoutesChildren />;
		}

		return (
			<Routes>
				<Route path={SIGNUP} element={<SignUp />} />
				<Route path={LOGIN} element={<SignIn />} />
				<Route path={UPLODAD_DOCUMENTS} element={<UploadDocuments />} />
				<Route path={NO_ROUTE} element={<Navigate to={LOGIN} />} />
				<Route path="*" element={<Navigate to={LOGIN} />} />
			</Routes>
		);
	}, [
		accessToken,
		loggedIn,
		login,
		signUpSessionStatus?.approveStatus,
		allowRoutes,
	]);

	if (
		(customUserTab.length === 0 && (accessToken || loggedIn)) ||
		isUserLogin ||
		loading
	) {
		return (
			<div className="loader-center">
				<Loader />
			</div>
		);
	}

	if (isEmailVerificationFailed) {
		return <LinkExpired onClose={handleClose} />;
	} else if (isEmailVerificationSuccess) {
		return <SuccessScreen />;
	}

	return renderMainRoutes;
};
