import { IAreaLineChart } from './types';

import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { kycSettingsState } from 'global-stores';

export const useAreaLineChart = () => {
	const kycSettings = useRecoilValue(kycSettingsState);
	const { settings } = useMemo(() => kycSettings, [kycSettings]);
	const { bodyFont } = settings ?? {};

	const getAreaLineChartConfig = ({
		id,
		isLoading,
		title,
		valuePrefix,
		valueSuffix,
		series,
	}: IAreaLineChart) => {
		const chart =
			(window as any).Highcharts?.chart(id, {
				chart: {
					type: 'area',
					height: 330,
					style: {
						fontFamily: bodyFont?.family !== '' ? bodyFont?.family : 'Poppins',
					},
				},

				credits: {
					enabled: false,
				},

				title: {
					text: title,
					align: 'left',
					style: {
						color: '#2F323D',
						fontWeight: '600',
						fontSize: '16px',
						lineHeight: '24px',
					},
				},

				subtitle: {
					text: null,
				},

				xAxis: {
					minTickInterval: 1,
				},

				yAxis: {
					gridLineDashStyle: 'dash',
					title: {
						text: null,
					},
					labels: {
						formatter: function (this: any) {
							const label = this.value.toLocaleString() + valueSuffix;
							return label;
						},
					},
				},

				legend: {
					enabled: series.length !== 1,
					symbolRadius: 5,
				},

				plotOptions: {
					series: {
						lineWidth: 2,
					},
					area: {
						marker: {
							enabled: false,
							symbol: 'circle',
							radius: 2,
							states: {
								hover: {
									enabled: true,
								},
							},
						},
					},
				},

				tooltip: {
					shared: true,
					valuePrefix,
					valueSuffix,
				},

				series,
			}) ?? {};

		if (isLoading) {
			chart.showLoading();
		} else {
			chart.hideLoading();
		}

		if (isLoading) {
			chart.showLoading();
		} else {
			chart.hideLoading();
		}
	};
	return { getAreaLineChartConfig };
};
