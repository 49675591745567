import { Button, Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { Elements } from '@stripe/react-stripe-js';
import { ReactNode, useCallback, useMemo } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import {
	AddPaymentDetails,
	ChooseIdentity,
	ErrorPage,
	IdentityHeader,
	SubscribeProcessing,
	SuccessPage,
} from './components';
import { IndetityFlowNavigate } from './constants';
import { useChooseIdentity, useDefaultCard, useUserIdentity } from './hooks';
import { BillingPaymentAddress, ChooseUserIdentity } from './store';
import {
	PaymentChoose,
	WireScreen,
	AddPaymentCard,
	CardView,
	paymentCardTypes,
	ACH,
	ChooseAchDefault,
	BillingInfoForm,
	PaySubscriptionAmount,
	ExpireCardAlert,
} from 'views/settings';
import { PaymentCardMethodsState } from 'global-stores';
import { KycWebComponents } from 'components';
import classNames from 'classnames';
import { stripePromise } from 'main';

type TUserIdentityFlow = {
	handleCloseModal: () => void;
	isOpen: boolean;
};
export const UserIdentityFlow = ({
	isOpen,
	handleCloseModal,
}: TUserIdentityFlow) => {
	const resetActiveIdx = useResetRecoilState(ChooseUserIdentity);
	const resetBillingAddress = useResetRecoilState(BillingPaymentAddress);
	const resetBillingPaymentAddress = useResetRecoilState(BillingPaymentAddress);

	// Hooks
	const { handleClickChoose } = useChooseIdentity();
	const {
		handleNextUserIdentity,
		handleBackUserIdentity,
		navigate,
		setNavigate,
		mapToNavigationNextLabel,
		isLoading,
		isLoadingPlaid,
		handleChooseNavigate,
		handleSumbitKycDetails,
		onSubmitBusinessIdentity,
		activeIdx,
	} = useUserIdentity({ handleCloseModal });

	const { isDefaultExist } = useDefaultCard();
	const paymentCardMethodsState = useRecoilValue(PaymentCardMethodsState);
	const billingAddress = useRecoilValue(BillingPaymentAddress);

	const { banks = [], cards = [] } = useMemo(
		() => paymentCardMethodsState ?? {},
		[paymentCardMethodsState]
	);

	const closeModal = useCallback(async () => {
		handleCloseModal();
		setTimeout(() => {
			resetActiveIdx();
			resetBillingAddress();
			resetBillingPaymentAddress();
		}, 500);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const mapToNavigationBody: Record<string, ReactNode> = useMemo(
		() => ({
			[IndetityFlowNavigate.ChooseIdentity]: (
				<ChooseIdentity handleClickChoose={handleClickChoose} />
			),
			[IndetityFlowNavigate.PersonalIdentity]:
				activeIdx === 'entity' ? (
					<KycWebComponents
						type="kyb"
						handleSuccess={onSubmitBusinessIdentity}
						businessType={activeIdx}
					/>
				) : (
					<KycWebComponents
						type="kyc"
						handleSuccess={handleSumbitKycDetails}
						businessType={activeIdx}
					/>
				),
			[IndetityFlowNavigate.Payment]: (
				<Elements stripe={stripePromise}>
					<AddPaymentDetails setNavigate={setNavigate} />
				</Elements>
			),
			[IndetityFlowNavigate.Processing]: <SubscribeProcessing />,
			[IndetityFlowNavigate.Success]: <SuccessPage handleClose={closeModal} />,
			[IndetityFlowNavigate.Error]: <ErrorPage handleClose={closeModal} />,
			[IndetityFlowNavigate.ChooseMethod]: <PaymentChoose />,
			[IndetityFlowNavigate.Wire]: <WireScreen />,
			[IndetityFlowNavigate.PaymentCard]: <AddPaymentCard from="SIGNUP" />,
			[IndetityFlowNavigate.CardView]: <CardView fromIdentity />,
			[IndetityFlowNavigate.SubscriptionReview]: <PaySubscriptionAmount />,
			[IndetityFlowNavigate.ExpireCard]: <ExpireCardAlert />,
			[IndetityFlowNavigate.AchView]: <ACH isSignup />,
			[IndetityFlowNavigate.WirePage]: <WireScreen isViewPage />,
			[IndetityFlowNavigate.DefaultAchPage]: <ChooseAchDefault />,
			[IndetityFlowNavigate.BillingForm]: <BillingInfoForm />,
		}),

		[
			activeIdx,
			closeModal,
			handleClickChoose,
			handleSumbitKycDetails,
			onSubmitBusinessIdentity,
			setNavigate,
		]
	);

	const mapToNavigationHeader: Record<string, JSX.Element> = useMemo(
		() => ({
			[IndetityFlowNavigate.ChooseIdentity]: (
				<IdentityHeader navigate={navigate} />
			),
			[IndetityFlowNavigate.ChooseMethod]: (
				<IdentityHeader navigate={navigate} />
			),
			[IndetityFlowNavigate.SubscriptionReview]: (
				<IdentityHeader navigate={navigate} />
			),

			[IndetityFlowNavigate.PaymentCard]: (
				<IdentityHeader navigate={navigate} />
			),
			[IndetityFlowNavigate.Wire]: <IdentityHeader navigate={navigate} />,
			[IndetityFlowNavigate.CardView]: <IdentityHeader navigate={navigate} />,
			[IndetityFlowNavigate.WirePage]: <IdentityHeader navigate={navigate} />,
			[IndetityFlowNavigate.AchView]: <IdentityHeader navigate={navigate} />,
			[IndetityFlowNavigate.DefaultAchPage]: (
				<IdentityHeader navigate={navigate} />
			),
			[IndetityFlowNavigate.BillingForm]: (
				<IdentityHeader navigate={navigate} />
			),
		}),
		[navigate]
	);

	const renderBody = useMemo(
		() => mapToNavigationBody[navigate],
		[mapToNavigationBody, navigate]
	);

	const renderTitle = useMemo(
		() => mapToNavigationHeader[navigate],
		[mapToNavigationHeader, navigate]
	);
	const hasBillingInfo = useMemo(() => {
		const allValuesPresent =
			Object.entries(billingAddress ?? {})
				.filter(([key]) => key !== 'address2')
				.every(([, value]) => value !== '') &&
			(billingAddress?.zip?.length ?? 0) > 3;
		return !allValuesPresent;
	}, [billingAddress]);

	const disableNextBtn = useMemo(() => {
		switch (navigate) {
			case IndetityFlowNavigate.ChooseIdentity:
				return false;
			case IndetityFlowNavigate.PersonalIdentity:
				return false;
			case IndetityFlowNavigate.BusinessIdentity:
				return false;
			case IndetityFlowNavigate.Payment:
				return false;
			case IndetityFlowNavigate.BillingForm:
				return hasBillingInfo;
			default:
				return false;
		}
	}, [hasBillingInfo, navigate]);

	const shouldHideBtn = useMemo(() => {
		if (banks.length > 0 || cards.length > 0) {
			return /Success|BusinessIdentity|PersonalIdentity|Processing|Payment|ExpireCard|Error/.test(
				navigate
			);
		} else {
			return /Success|Processing|BusinessIdentity|PersonalIdentity|Payment|Error|AchView|ExpireCard|CardView/.test(
				navigate
			);
		}
	}, [banks?.length, cards?.length, navigate]);

	const isHideBackBtn = useMemo(() => {
		if (isDefaultExist) {
			return /Wire|AchDefaultAccount|CardView|AchView|WireView/.test(navigate);
		} else {
			return /AchDefaultAccount/.test(navigate);
		}
	}, [navigate, isDefaultExist]);

	const choosePaymentNavigation = useCallback(
		(type: any) => {
			return classNames('identity-user-flow-modal_select_cards', {
				'identity-user-flow-modal_select_cards_active': type.key === navigate,
				'identity-user-flow-modal__wire': type.key === 'WireView',
			});
		},
		[navigate]
	);

	const renderSideNavigation = useMemo(
		() => (
			<div className="identity-user-flow-modal_select">
				{paymentCardTypes.map(type => {
					return (
						<div
							key={type.key}
							onClick={handleChooseNavigate(type.key)}
							className={choosePaymentNavigation(type)}
						>
							{type.label}
						</div>
					);
				})}
			</div>
		),
		[choosePaymentNavigation, handleChooseNavigate]
	);

	const isShowSidebar = useMemo(
		() => /CardView|AchView|WireView/.test(navigate),
		[navigate]
	);

	const isShowCancelIcon = useMemo(
		() =>
			[
				IndetityFlowNavigate.BillingForm,
				IndetityFlowNavigate.CardView,
				IndetityFlowNavigate.AchView,
				IndetityFlowNavigate.WirePage,
				IndetityFlowNavigate.Success,
				IndetityFlowNavigate.PersonalIdentity,
				IndetityFlowNavigate.BusinessIdentity,
			].includes(navigate),
		[navigate]
	);

	const isKYBKYCPage = useMemo(() => {
		return /BusinessIdentity|PersonalIdentity/.test(navigate);
	}, [navigate]);

	const identityClass = classNames('identity-user-flow-modal--body', {
		'identity-user-flow-modal--payment-body':
			navigate === IndetityFlowNavigate.ChooseMethod,
		'identity-user-flow-modal--hide-scroll': isKYBKYCPage,
	});

	const identityModal = classNames('identity-user-flow-modal', {
		'identity-user-flow-signup-modal':
			navigate === IndetityFlowNavigate.PersonalIdentity,
	});

	return (
		<>
			<Modal
				isOpen={isOpen}
				modalName="User-Identity-flow"
				closeModal={closeModal}
				className={identityModal}
				showCloseBtn={isShowCancelIcon}
				isStopOutsideClick={false}
				closeOnEscBtn={false}
				title={renderTitle}
			>
				<div className={identityClass}>
					{isShowSidebar && renderSideNavigation}
					{isLoading ? (
						<div className="loader-center">
							<Loader />
						</div>
					) : (
						renderBody
					)}
				</div>
				{!shouldHideBtn && (
					<div className="identity-user-flow-modal--btns">
						{!isHideBackBtn && (
							<Button
								handleClick={handleBackUserIdentity}
								label="Back"
								type="button__filled button__filled--secondary button__large"
							/>
						)}
						<Button
							handleClick={handleNextUserIdentity}
							label={mapToNavigationNextLabel}
							loader={
								isLoading || isLoadingPlaid ? (
									<Loader type="loader" dimension={20} />
								) : (
									<></>
								)
							}
							type="button__filled button__filled--primary button__large"
							disabled={disableNextBtn || isLoadingPlaid || isLoading}
						/>
					</div>
				)}
			</Modal>
		</>
	);
};
