import { Image } from '@storybook/image';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { loginState, PipelineMasterSteps, PipelineTemplateState } from 'global-stores';
import { useNotification } from 'hooks';
import { useDashboradhook } from 'views/routes-children';
import {
	SelectedCheckboxStepsState,
	SelectedStepsState,
	useOnboardingHook,
} from '../../store';
import { proofReadingKey } from 'views/pipelines';
import './builder-left-nav.scss';
import { FundsPayoutHideBusiness } from 'views/pipeline';

interface IKYBMetaDataType {
	_id: string;
	key: string;
	type: string;
	default: string;
	description: string | string[];
	label: string;
	price?: number;
}

export const BuilderLeftNav = ({ onDragStart }: any) => {
	const templateInit = useRecoilValue(PipelineTemplateState);
	const selectedCheckbox = useRecoilValue(SelectedCheckboxStepsState);
	const steps = useRecoilValue(PipelineMasterSteps);
	const { business = '' } = useRecoilValue(loginState);
	const [selectedSteps, setSelectedSteps] = useRecoilState(SelectedStepsState);
	const { errorNotification } = useNotification();
	const { getPipelineInit, getTemplateInit } = useDashboradhook();
	const { isPricingHide } = useOnboardingHook();

	//constants
	const { proofVerification } = proofReadingKey;

	const globalActions = useMemo(() => {
		const stepActions: any[] = [];
		selectedSteps.forEach(({ actions }) => {
			stepActions.push(...actions);
		});
		return stepActions;
	}, [selectedSteps]);

	const handleSelectStep = useCallback(() => {
		const filtered = steps.filter(step => selectedCheckbox[step.key]);
		const isKycAvailable = filtered.findIndex(({ key }) => key === 'kyc') > -1;
		const isAmlAvailable = filtered.findIndex(({ key }) => key === 'aml') > -1;
		if (isAmlAvailable) {
			if (!isKycAvailable) {
				errorNotification('Oops! can not select AML without KYC');
				return;
			}
		}
		setSelectedSteps(filtered);
		// eslint-disable-next-line
	}, [selectedCheckbox, steps]);

	useEffect(() => {
		handleSelectStep();
		// eslint-disable-next-line
	}, [steps]);

	useEffect(() => {
		if (steps.length === 0) {
			getPipelineInit();
		} // eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!templateInit) getTemplateInit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// here we are removing the face auth from the steps need to remove it if we again wanted to add the face auth as a step
	const navActionsWithoutAuth = useMemo(() => {
		// only for now proofVerification is hide for linear flow
		return globalActions.filter(
			action =>
				action.key !== 'authentication' &&
				action.key !== 'facialRecognition'
		);
	}, [globalActions]);

	const getActionInfo = useCallback((actionKey: string) => {
		if (actionKey === 'oneTime') {
			return 'Verification';
		} else if (actionKey === 'dailyOnGoing') {
			return 'Per year';
		} else return 'Verification';
	}, []);


	const getAllLists = useCallback(
		(_id: string, label: string, price: any, key: string, metadata: any) => {
			
			return (
				<div
					key={`${_id}_${key}`}
					className="BuilderLeftNav--list"
					onDragStart={(event: any) => onDragStart?.(event, key)}
					draggable
				>
					<div className="BuilderLeftNav--label">{label}</div>
					{key === 'successScreenCompletion' && (
						<>
							<div
								key={'successScreenCompletion'}
								className="BuilderLeftNav--bottom"
							>
								<div className="BuilderLeftNav--key">Success Screen</div>
								{isPricingHide && (
									<div className="BuilderLeftNav--price-field">
										<div className="BuilderLeftNav--price">
											${(price ?? 0).toFixed(2)}
										</div>
										<div className="BuilderLeftNav--slashes">/</div>
										<div className="BuilderLeftNav--verification">
											Verification
										</div>
									</div>
								)}
							</div>
						</>
					)}
					{key === 'allId' && (
						<>
							{(metadata ?? []).map((meta: any) => (
								<div key={meta?.key} className="BuilderLeftNav--bottom">
									<div className="BuilderLeftNav--key">
										{meta?.label ?? 'KYC'}
									</div>
									{isPricingHide && (
										<div className="BuilderLeftNav--price-field">
											<div className="BuilderLeftNav--price">
												${(meta?.price ?? 0).toFixed(2)}
											</div>
											<div className="BuilderLeftNav--slashes">/</div>
											<div className="BuilderLeftNav--verification">
												Verification
											</div>
										</div>
									)}
								</div>
							))}
						</>
					)}
					{key === 'formAction' && (
						<div className="BuilderLeftNav--bottom">
							<div className="BuilderLeftNav--key">{label ?? 'Form'}</div>
							{isPricingHide && (
								<div className="BuilderLeftNav--price-field">
									<div className="BuilderLeftNav--price">
										${price?.toFixed(2)}
									</div>
									<div className="BuilderLeftNav--slashes">/</div>
									<div className="BuilderLeftNav--verification">
										Verification
									</div>
								</div>
							)}
						</div>
					)}

					{key === 'kybForm' && (
						<div className="BuilderLeftNav--bottom">
							<div className="BuilderLeftNav--key">{label ?? 'KYB Form'}</div>
							{isPricingHide && (
								<div className="BuilderLeftNav--price-field">
									<div className="BuilderLeftNav--price">
										${price?.toFixed(2)}
									</div>
									<div className="BuilderLeftNav--slashes">/</div>
									<div className="BuilderLeftNav--verification">
										Verification
									</div>
								</div>
							)}
						</div>
					)}
					{key === 'accountForm' && (
						<div className="BuilderLeftNav--bottom">
							<div className="BuilderLeftNav--key">
								{label ?? 'Account Form'}
							</div>
							{isPricingHide && (
								<div className="BuilderLeftNav--price-field">
									<div className="BuilderLeftNav--price">
										${price?.toFixed(2)}
									</div>
									<div className="BuilderLeftNav--slashes">/</div>
									<div className="BuilderLeftNav--verification">
										Verification
									</div>
								</div>
							)}
						</div>
					)}
					{key === 'amlVerification' && (
						<div className="BuilderLeftNav">
							{metadata.length > 0 &&
								metadata[0].options?.map((res: any, index: number) => (
									<div
										className="BuilderLeftNav--key-wrapper"
										key={`${index.toString()}_${res.key}`}
									>
										<div className="BuilderLeftNav--key">
											{res.label ?? 'fundInvestmentVerification'}
										</div>
										{isPricingHide && (
											<div className="BuilderLeftNav--price-field">
												<div className="BuilderLeftNav--price">
													${res?.price?.toFixed(2)}
												</div>
												<div className="BuilderLeftNav--slashes">/</div>
												<div className="BuilderLeftNav--verification">
													{getActionInfo(res?.key ?? '')}
												</div>
											</div>
										)}
									</div>
								))}
						</div>
					)}
					{key === proofVerification && (
						<div className="BuilderLeftNav--bottom">
							<div className="BuilderLeftNav--key">
								{label ?? 'amlVeridjaskjkdbjkabkbakjfication'}
							</div>
							{isPricingHide && (
								<div className="BuilderLeftNav--price-field">
									<div className="BuilderLeftNav--price">
										${price?.toFixed(2)}
									</div>
									<div className="BuilderLeftNav--slashes">/</div>
									<div className="BuilderLeftNav--verification">
										Verification
									</div>
								</div>
							)}
						</div>
					)}
					{key === 'kybVerification' && (
						<>
							{(metadata ?? []).map((meta: IKYBMetaDataType) => (
								<div key={meta?.key} className="BuilderLeftNav--bottom">
									<div className="BuilderLeftNav--key">
										{meta?.label ?? 'KYC'}
									</div>
									{isPricingHide && (
										<div className="BuilderLeftNav--price-field">
											<div className="BuilderLeftNav--price">
												${(meta?.price ?? 0).toFixed(2)}
											</div>
											<div className="BuilderLeftNav--slashes">/</div>
											<div className="BuilderLeftNav--verification">
												Verification
											</div>
										</div>
									)}
								</div>
							))}
						</>
					)}
					{key === 'accreditationVerify' && (
						<div className="BuilderLeftNav">
							{metadata.length > 0 &&
								metadata[0].options?.map((res: any) => (
									<div
										className="BuilderLeftNav--key-wrapper"
										key={`${res.label}`}
									>
										<div className="BuilderLeftNav--key">
											{res.label ?? 'accreditationVerify'}
										</div>
										{isPricingHide && (
											<div className="BuilderLeftNav--price-field">
												<div className="BuilderLeftNav--price">
													${res.price?.toFixed(2)}
												</div>
												<div className="BuilderLeftNav--slashes">/</div>
												<div className="BuilderLeftNav--verification">
													Verification
												</div>
											</div>
										)}
									</div>
								))}
						</div>
					)}
					{key === 'signAgreementVerification' && (
						<>
							{(metadata?.[0]?.options ?? [{ label, price }]).map(
								(option: any, index: number) => (
									<div
										className="BuilderLeftNav--bottom"
										// eslint-disable-next-line react/no-array-index-key
										key={`${index}_${label}`}
									>
										<div className="BuilderLeftNav--key">
											{option?.label ?? label ?? 'signAgreementVerification'}
										</div>
										{isPricingHide && (
											<div className="BuilderLeftNav--price-field">
												<div className="BuilderLeftNav--price">
													${(option?.price ?? price)?.toFixed(2)}
												</div>
												<div className="BuilderLeftNav--slashes">/</div>
												{/* Arun Kumar : For Sign Aggrement verification changed into Packet */}
												<div className="BuilderLeftNav--verification">
													Packet
												</div>
											</div>
										)}
									</div>
								)
							)}
						</>
					)}
					{key === 'fundInvestmentVerification' && (
						<div className="BuilderLeftNav">
							{metadata.length > 0 &&
								metadata[0].options?.map((res: any, index: number) => {
									if (FundsPayoutHideBusiness.includes(business ?? "") && res?.key === 'payOut') {
										return null;
									}
									return (
										<div
											className="BuilderLeftNav--key-wrapper"
											key={`${index.toString()}_${res.key}`}
										>
											<div className="BuilderLeftNav--key">
												{res.label ?? 'fundInvestmentVerification'}
											</div>
											{isPricingHide && (
												<div className="BuilderLeftNav--price-field">
													<div className="BuilderLeftNav--price">
														${price?.toFixed(2)}
													</div>
													<div className="BuilderLeftNav--slashes">/</div>
													<div className="BuilderLeftNav--verification">
														Verification
													</div>
												</div>
											)}
										</div>
									);
								})}
						</div>
					)}
				</div>
			);
		},
		[getActionInfo, business, isPricingHide, onDragStart, proofVerification]
	);

	//Shahbaaz Need to replace after complex flow demo
	// This code will run only for complex flow
	useMemo(() => {
		const kybFormKey = {
			label: 'Entity/KYB Document Requirements',
			description: 'Know Your Business Action',
			key: 'kybForm',
			price: 0,
			metadata: [],
			_id: 'kybForm',
			stepKey: 'kybForm',
		};
		const accountFormKey = {
			label: 'Individual or Business Questionnaire.',
			description: 'Individual or Business Questionnaire.',
			key: 'accountForm',
			price: 0,
			metadata: [],
			_id: 'accountForm',
			stepKey: 'accountForm',
		};
		navActionsWithoutAuth.push(kybFormKey, accountFormKey);
	}, [navActionsWithoutAuth]);

	const renderMainComponent = useMemo(() => {
		return navActionsWithoutAuth.map(
			({ _id = '', label = '', key = '', price = 0.0, metadata = [] }) =>
				getAllLists(_id, label, price, key, metadata)
		);
	}, [getAllLists, navActionsWithoutAuth]);
	return (
		<div className="BuilderLeftNav--container">
			<div className="BuilderLeftNav--head">Actions List</div>
			<div className="BuilderLeftNav--desc">
				Drag and drop actions in onboarding flow.
			</div>

			{navActionsWithoutAuth.length === 0 ? (
				<Image fileName="ActionList.svg" className="illustration__emptyList" />
			) : (
				renderMainComponent
			)}
		</div>
	);
};
